import { createRouter, createWebHistory } from "vue-router";

import Home from '../views/Home.vue'
import Community from '../views/Community.vue'
import Contact from '../views/Contact.vue'
import Tab from '../views/Tab.vue'
import ImageGallery from '../views/ImageGallery.vue'
import Bookings from '../views/Bookings.vue'
import CourseDetails from '../views/CourseDetails.vue'
import Courses from '../views/Courses.vue'
import BookingHoursAdd from '../views/BookingHoursAdd.vue'
import BookingDaysAdd from '../views/BookingDaysAdd.vue'
import BookingDetails from '../views/BookingDetails.vue'

import SaleHome from '../views/SaleSite/SaleHome.vue'
import SaleOrder from '../views/SaleSite/SaleOrder.vue'
import SaleTrial from '../views/SaleSite/SaleTrial.vue'
import SaleQnA from '../views/SaleSite/SaleQnA.vue'
import SaleDemo from '../views/SaleSite/SaleDemo.vue'
import SaleCost from '../views/SaleSite/SaleCost.vue'
import SaleNews from '../views/SaleSite/SaleNews.vue'
import SaleHistory from '../views/SaleSite/SaleHistory.vue'
import SaleExample from '../views/SaleSite/SaleExample.vue'

import NotFound from '../views/NotFound.vue'

const routes = [   
    {
        path: '/',
        component: Home,
        name: 'domain_home'
    },  
    {
        path: '/community',
        component: Community,
        name: 'domain_community'
    },   
    {
        path: '/image-gallery',
        component: ImageGallery,
        name: 'domain_imageGallery'
    },    
    {
        path: '/contact',
        component: Contact,
        name: 'domain_contact'
    },    
    {
        path: '/tab/:tabid',
        component: Tab,
        name: 'domain_tab'
    },    
    {
        path: '/booking-hours/:bookingid',
        component: BookingHoursAdd,
        name: 'domain_bookingHoursAdd'
    },    
    {
        path: '/booking-days/:bookingid',
        component: BookingDaysAdd,
        name: 'domain_bookingDaysAdd'
    },    
    {
        path: '/bookings',
        component: Bookings,
        name: 'domain_bookings'
    },     
    {
        path: '/courses/:courseid',
        component: CourseDetails,
        name: 'domain_courseDetails'
    },     
    {
        path: '/courses',
        component: Courses,
        name: 'domain_courses'
    },     
    {
        path: '/sale/',
        component: SaleHome,
        name: 'saleHome'
    },       
    {
        path: '/sale/order/',
        component: SaleOrder,
        name: 'saleOrder'
    },    
    {
        path: '/sale/trial/',
        component: SaleTrial,
        name: 'saleTrial'
    },    
    {
        path: '/sale/demo/',
        component: SaleDemo,
        name: 'saleDemo'
    },    
    {
        path: '/sale/price/',
        component: SaleCost,
        name: 'saleCost'
    },           
    {
        path: '/sale/example/',
        component: SaleExample,
        name: 'saleExample'
    },    
    {
        path: '/b/:id',
        component: BookingDetails,
        name: 'bookingDetails'
    },    
    {
        path: '/:id/',
        component: Home,
        name: 'home'
    },    
    {
        path: '/:id/community',
        component: Community,
        name: 'community'
    },        
    {
        path: '/:id/image-gallery',
        component: ImageGallery,
        name: 'imageGallery'
    },    
    {
        path: '/:id/contact',
        component: Contact,
        name: 'contact'
    },    
    {
        path: '/:id/tab/:tabid',
        component: Tab,
        name: 'tab'
    },    
    {
        path: '/:id/booking-hours/:bookingid',
        component: BookingHoursAdd,
        name: 'bookingHoursAdd'
    },    
    {
        path: '/:id/booking-days/:bookingid',
        component: BookingDaysAdd,
        name: 'bookingDaysAdd'
    },    
    {
        path: '/:id/bookings',
        component: Bookings,
        name: 'bookings'
    }, 
    {
        path: '/:id/courses/:courseid',
        component: CourseDetails,
        name: 'courseDetails'
    },      
    {
        path: '/:id/courses',
        component: Courses,
        name: 'courses'
    },       
    {
        path: '/:pathMatch(.*)*',
        component: NotFound
    }

]
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { top: 0 }
      },

})

export default router