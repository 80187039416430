<script>
import config from "@/helpers/config"
import niceFunctions from "@/helpers/niceFunctions"
import SvgTransition from '@/components/SvgTransition.vue'
export default {
    components: { SvgTransition },
    data() {
        return {
            occations: [],
            instructors: [],
            registerForm: { full_name: '', email: '', mobile_no: '' }
        }
    },
    created() {  
        this.$cache.createCache('publicPage', 'public-page/',  {id: this.$route.params.id, domain: window.location.hostname });      
        this.getCourse();
    },
    computed: {
        url() { return config.urlToServer },  
        publicPageColors() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPageColors : null },
        publicPage() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPage : null },
        availableCourses() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.availableCourses : [] },
        height() { return window.innerHeight/1.3 + 'px' },       
        settings() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.settings : null },
        hasSettings() { return this.settings },
        course() { return this.availableCourses.find(x => x.id == this.$route.params.courseid) }
    },
    methods:
    {
        getCourse()
        {
            var self = this;
            this.$communicator.get('get-public-course/' + this.$route.params.courseid)
            .then(function(data)
            {             
                self.occations = data.occations;
                self.instructors = data.instructors;
            })
            .fail(function(error) {              
            }); 
        },
        getColor(location)
        {
            return niceFunctions.getColor(this.publicPageColors, location)
        }     
    }
}
</script>
<template>
   <section class="public-page" v-if="publicPage != null && course && hasSettings">   
        <div class="top-public position-relative" :style="{ 'height': height, 'background-image': 'url(' + url + 'uploads/'+ publicPage.customer_id+'/images/'+course.image_name + ')' }">
            <SvgTransition :publicPage="publicPage"></SvgTransition>   
        </div>
        <div class="text-section overflow-auto" style="padding-top: 70px;">
            <div class="g-70">
                <div class="CourseDetails-section">
                    <h1 :style="{ 'color': getColor('header_color_on_white_bg') }" class="margin-top-25px">{{ course.name }}</h1>  
                    {{ course.description }}    
                    <div class="CourseDetails-panel" :style="{ 'background-color':  getColor('background_color') }">
                        <div>
                            <h6>Startar</h6>
                            {{ course.course_start }}
                        </div>
                        <div>
                            <h6>Sista anmälningsdag</h6>
                            {{ course.last_day_of_application }}
                        </div>
                        <div>
                            <h6>Pris</h6>
                            {{ course.price }} kr
                        </div>
                        <div>
                            <h6>Antal tillfällen</h6>
                            {{ course.number_of_occations }} st
                            <p v-for="(occation, index) in occations">
                                tillfälle {{ index+1 }}:    {{ occation.occation_duration }}
                            </p>
                        </div>
                        <div>
                            <h6>Plats</h6>
                            {{ course.location_name }}
                        </div>
                        <div>
                            <h6>Max antal deltagare</h6>
                            {{ course.max_number_of_participents }}
                        </div>
                    </div>
                </div>
                <div style="margin: 40px 30px 0px 0px" class="pc-only">
                    <h3>Anmälan</h3>
                    <label>Ditt namn<span style="font-family: 'Courier New', Courier, monospace;">*</span></label>
                    <input type="text" v-model="registerForm.full_name" style="border: 1px solid #ddd" />
                    <div class="row-form">
                        <div class="g-100"> 
                            <label>Din email (Hit skickas ett bekräftelsemail)<span style="font-family: 'Courier New', Courier, monospace;">*</span></label>
                            <input type="text" v-model="registerForm.email" style="border: 1px solid #ddd" />
                        </div>            
                        <div class="g-50">
                            <label>Ditt mobilnummer</label>
                            <input type="text" v-model="registerForm.mobileNo" style="border: 1px solid #ddd" />
                        </div>              
                        <div class="g-50">                           
                            <button class="btn">Skicka anmälan</button>
                        </div>              
                    </div>

                </div>
            </div> 
            <div class="g-30">
                <div class="CourseDetails-instructors">
                    <h2 :style="{ 'color': getColor('header_color_on_white_bg') }">{{ settings.CourseInstructor.plural }}</h2>
                    <div v-for="instructor in instructors" :style="{ 'background-color':  getColor('background_color') }">
                        <img :src="url + 'uploads/'+ publicPage.customer_id+'/images/thumbnails/'+ instructor.image_name" />  
                        <h3 style="padding:0px 15px">{{ instructor.name }}</h3>
                        <p> {{ instructor.description }}</p>
                    </div>
                    
                </div>
            </div>  
            <div style="margin: 40px 0px 0px 0px" class="mobile-only">
                    <h3>Anmälan</h3>
                    <label>Ditt namn<span style="font-family: 'Courier New', Courier, monospace;">*</span></label>
                    <input type="text" v-model="registerForm.full_name" style="border: 1px solid #ddd" />
                    <div class="row-form">
                        <div class="g-100"> 
                            <label>Din email (Hit skickas ett bekräftelsemail)<span style="font-family: 'Courier New', Courier, monospace;">*</span></label>
                            <input type="text" v-model="registerForm.email" style="border: 1px solid #ddd" />
                        </div>            
                        <div class="g-50">
                            <label>Ditt mobilnummer</label>
                            <input type="text" v-model="registerForm.mobileNo" style="border: 1px solid #ddd" />
                        </div>              
                        <div class="g-50">                           
                            <button class="btn">Skicka anmälan</button>
                        </div>              
                    </div>

                </div>             
        </div>      
   </section>
</template>
<style scoped>
    .CourseDetails-section {
        margin: 0px 30px 0px 0px;
    }
    .CourseDetails-panel {
        padding:15px;
        margin-top: 30px;
        filter: brightness(1.2);
        overflow: auto;
        
    }
    
    .CourseDetails-panel div {
        width:33%;
        padding-bottom:30px; 
        display: inline-block; 
        vertical-align: top;    
    }
    
    .CourseDetails-panel p {
        margin: 0px;   
    }
    
    .CourseDetails-instructors {
        padding: 15px;
    }
    
    .CourseDetails-instructors div {
        padding: 10px;
    }
    
    .CourseDetails-instructors p {
        font-size: 14px;
        text-align: justify;
        line-height: 24px;
        padding: 0px 15px;
    }
    
    .CourseDetails-instructors img {
       width:100%;
    }

    h6{
        font-size: 13pt;
        margin: 7px 0px;
        font-weight: 500;
    }
   

    button {
        width: calc(100% - 8px);
        margin-top: 25px;
        padding: 13px 0px;
        font-size: 12pt;
    }


@media only screen and (max-width: 900px) {
    .CourseDetails-instructors {
        padding: 30px 0px;
    }

      .CourseDetails-section {
        margin: 0px;
    }

    td {
        width:100%;
        padding-bottom:30px;
    }

    .CourseDetails-panel div {
        width:50%;       
    }

}
</style>