<script>
import Logo from '@/components/Logo.vue'
import links from '@/helpers/saleLinks'

export default {
    components: { Logo },
    data() {
        return {      
          selectedTab: '',
          links: links.links()
        }
    },
    methods:
    {
        goTopage(page)
        {
            $(window).scrollTop(0);   
           
            this.$router.push({ name: page })        
        },
        scrollToElement(elementId) {
            var scrollTop = document.getElementById(elementId).getBoundingClientRect().top + document.documentElement.scrollTop - 100;
            $("html, body").animate({ scrollTop: scrollTop });
        }
    },
    computed: {
        selectedPage() { return this.$route.name }
  },
}
</script>
<template>
<div>
    <div class="public-footer">
        <section class="public-footer-section">          
            <div v-if="!this.$isMobile">             
                <Logo :width="160" :color="'none'" :useBlackLogo="true" :opacity="0.1"></Logo>                  
            </div>
            <div> 
                <h2>Meny</h2>        
                <ul>
                    <li v-for="link in links" @click="goTopage(link.componentName)">
                    {{  link.title }}
                    </li>                    
                </ul>     
            </div>
          <div v-if="selectedPage == 'saleHome'">
            <h2>Sektioner</h2>
            <ul>                  
                <li @click="scrollToElement('SaleHomeWhatIs')">Vad får du av jamii?</li>
                <li @click="scrollToElement('SaleHomeHelpYou')">Jamii i punkter</li>
                <li @click="scrollToElement('SaleHomeWhoAreWe')">Jamii-gänget</li>
            </ul>
          </div>
          <div>
            <h2>Kontakt</h2>
            <a href="mailto:admin@jamii.se"> admin@jamii.se</a>
            <p style="line-height: 25px; margin-top:10px">
                Foogler AB<br>
                Barkvägen 5<br>
                388 96 Hagby
            </p>         
          </div>
         
           
        </section> 
        <div class="public-footer-hr">          
            <a href="https://app.jamii.se" class="float-right" target="_blank">Logga in i Jamii</a>
            <p>&copy; {{ new Date().getFullYear() }} Foogler AB  | <a href="mailto:info@foogler.se"> info@foogler.se</a></p>
        </div>             
    </div>
</div>
</template>


<style scoped>
.public-footer
{
    width:100%;
    background-color: rgb(225, 182, 117);
    padding: 70px 0px 140px 0px;
    position: relative;
    color: rgba(31, 57, 87, 1);
    background-color: #e5e5f7;
    opacity: 1;
    background-image:  repeating-linear-gradient(45deg, rgb(225, 182, 117) 25%, transparent 25%, transparent 75%, rgb(225, 182, 117) 75%,rgb(225, 182, 117)), repeating-linear-gradient(45deg, rgb(225, 182, 117) 25%, rgba(225, 182, 117, 0.96) 25%, rgba(225, 182, 117, 0.96) 75%, rgb(225, 182, 117) 75%, rgb(225, 182, 117));
    background-position: 0 0, 10px 10px;
    background-size: 20px 20px;
}

.public-footer-hr
{
    position: absolute;
    bottom: 40px;
    left: 0px;
    right: 0px;
    width: 95%;
    margin: auto;
    border-top: 1px solid  rgb(173, 139, 86);
    padding-top: 15px;
}
.public-footer a
{
   color: rgba(31, 57, 87, 1);
   font-weight: bold;

}

.public-footer section
{
    max-width: 1200px;
    margin: auto;
}

.public-footer-section div
{
    display: inline-block;
    vertical-align: top;
    width: 25%;
}

.public-footer ul
{
    list-style: none;
    padding: 0px;
}

.public-footer li
{
   margin-bottom:10px;
   cursor: pointer;
}

.public-footer li:hover
{
   font-weight: bold;
}


button{
        padding:15px 50px;
        margin: 20px 40px 0px 0px;
        font-size: 20px;
        font-family: 'Raleway', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: rgb(179, 140, 81);
        color: white;
        border: 0px;
        border-radius: 6px;
        cursor: pointer;
        letter-spacing: 1px;
        width: 200px;
    }

button:hover{
       
        background-color: rgb(204, 159, 91);
       font-weight: bold;
    }




@media only screen and (max-width: 900px) {
    .public-footer div
    {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 40px;
    }

    .public-footer-hr
    {
        text-align: center !important;
        padding: 10px;
        width: calc(100% - 20px) !important;
        margin-bottom: 0px !important;

    }

    .public-footer-hr a {
        float: none !important;
    }
}
</style>