<script>
import Menu from './components/Menu.vue'
import MobileMenu from './components/MobileMenu.vue'
import Footer from './components/Footer.vue'
import Home from './views/Home.vue'
import Messages from './components/Messages.vue'
import SaleMenu from '@/views/SaleSite/Menu.vue'
import SaleMobileMenu from '@/views/SaleSite/MobileMenu.vue'
import SaleFooter from '@/views/SaleSite/SaleFooter.vue'

export default {
  name: 'App',
  components: {
    Menu, MobileMenu, Home, Footer, Messages, SaleMenu, SaleFooter, SaleMobileMenu
  },
  data() {
    return {
      scrollTop: 0,
      salePages: ['saleHome', 'saleFunctions','saleOrder', 'saleDemo', 'saleHistory', 'saleTrial','saleCost', 'saleQnA', 'saleNews','saleExample']
    }
  },
  created() {

    if(window.location.href == 'https://www.jamii.se/')
    {
      this.$router.push({ name: 'saleHome' })
    } 
    var self = this;
    // Hantera scrollning för icke-mobila enheter
      $(window).scroll(function() {
        self.scrollTop = $(window).scrollTop();
    });

    // Hantera touchmove för mobila enheter
    document.addEventListener('touchmove', function() {
      self.scrollTop = window.scrollY;
    }, { passive: true });
  },
  computed:
  {
    isSalePage() {
      return this.salePages.some(x => x == this.$route.name);
    },
    isBookingDetails() {
      return this.$route.name == 'bookingDetails';
    }
  }

}
</script>

<template>
  
  <div v-if="!isSalePage && !isBookingDetails">
    <Menu v-if="!this.$isMobile" :windowScroll="scrollTop"></Menu>
    <MobileMenu v-if="this.$isMobile"></MobileMenu>
    <router-view :windowScroll="scrollTop"></router-view>
    <Footer></Footer>
    <Messages></Messages>
  </div>
  
  <div v-if="isBookingDetails">
    <router-view :windowScroll="scrollTop"></router-view>
  </div>
  <div v-if="isSalePage">  
    <SaleMenu v-if="!this.$isMobile" :windowScroll="scrollTop"></SaleMenu>
    <SaleMobileMenu v-if="this.$isMobile"></SaleMobileMenu>
    <router-view :windowScroll="scrollTop"></router-view>
    <SaleFooter></SaleFooter>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>

