<script>
import PopUp from '@/components/PopUp.vue'
import Switch from '@/components/Switch.vue'
import message from '@/helpers/message'
import config from "@/helpers/config"
import niceFunctions from "@/helpers/niceFunctions"
import SvgTransition from '@/components/SvgTransition.vue'

export default {
    components: { PopUp, Switch, SvgTransition },
    data(){
        return {
            month: '',
            currentDate: null,
            noOfDays: null,
            today: new Date(),
            months: this.$isMobile ? 
                ['jan', 'feb', 'mars', 'april', 'maj', 'juni', 'juli', 'aug','sept','okt', 'nov', 'dec']
                : ['januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli', 'augusti','september','oktober', 'november', 'december'],
            days: ['Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör', 'Sön'],
            daysInNumbers: [6,0,1,2,3,4,5],
            allDaysInMonth: [] ,
            allBookingTimes: null,           
            bookingItem: null,
            newBookingDays: { 
                id: 0,                
                start_date: null, 
                end_date: null, 
                comments: '', 
                booking_item_id: null,
                duration_time: null,
                duration_unit: null,
                email: '',
                mobileNo: '',
                send_reminder_to_mobile_no: false,
                agree_to_terms: false,
                full_name: '',
                customer_name: '',
                booking_item_name: ''
             },
            indexOfStart: null,
            indexOfEnd: null,
            showSummary: false,
            showConfirmation: false,
            showBookingBar: false         
        }
    },
    props: ['windowScroll'],
    created()
    {    
        this.currentDate = new Date();
        this.getBooking();
        this.$cache.createCache('publicPage', 'public-page/',  {id: this.$route.params.id, domain: window.location.hostname });

        var self = this;
       
                 
    },
    methods:
    {
        getBooking()
        {
            var self = this;
            this.$communicator.get('add-public-booking/' + this.$route.params.bookingid)
            .then(function(data)
            {             
                self.bookingItem = data.booking;
                self.allBookingTimes = data.allBookings;
                self.renderCalenderMonth(self.currentDate);
            })
            .fail(function(error) {              
            }); 
        },
        closeConfirm()
        {
            this.$router.push({ name: 'home', params: { id: this.$route.params.id } });
        },
        showPrevMonth()
        {
            this.emptyBookingValues();
            this.currentDate.setMonth(this.currentDate.getMonth()-1);
            this.renderCalenderMonth(this.currentDate);
        },
        showNextMonth()
        {
            this.emptyBookingValues();
            this.currentDate.setMonth(this.currentDate.getMonth()+1);
            this.renderCalenderMonth(this.currentDate);
        },
        renderCalenderMonth(date)
        {
            this.month = this.months[date.getMonth()];
            this.noOfDays = new Date(date.getFullYear(), date.getMonth()+1, 0).getDate();         
            var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);            
            var dayInMonth = 1;
            var dayInNextMonth = 1;

            var list = [];
            this.allBookingTimes.forEach(function(time) {
                var start_date = new Date(time.formatted_start_date);
                if(start_date.getMonth() == date.getMonth())
                {
                    for(var i = 0; i < (time.duration_time); i++)
                    {
                        list.push(date.getDate() + i);
                    }
                }
            })

            //fill calender with days
            this.allDaysInMonth = [];
            for(var i = 0; i <= 41; i++)
            {
                if(i >= this.daysInNumbers[firstDay.getDay()] && dayInMonth <= this.noOfDays)
                {
                   //calculate day date and if its sunday and if its today
                    var dayDate = new Date(date.getFullYear(), date.getMonth(), dayInMonth);                  
                    var isToday = this.today.getFullYear() == dayDate.getFullYear() && this.today.getMonth() == dayDate.getMonth() && this.today.getDate() == dayDate.getDate();

                    var dateString = this.getDateString(dayDate);
                    var bookingStart = this.allBookingTimes.find(x => x.formatted_start_date == dateString);
                    var bookingEnd = this.allBookingTimes.find(x => x.formatted_end_date == dateString);                  
     
                    this.allDaysInMonth.push({ 
                        id: i, 
                        date: dayDate, 
                        dateString: dateString, 
                        day: dayInMonth, 
                        isCurrentMonth: true, 
                        isSunday: dayDate.getDay() == 0, 
                        isToday: isToday, 
                        bookingStart: bookingStart, 
                        bookingEnd: bookingEnd,
                        occupied: list.some(x => x == dayInMonth)
                      });

                      dayInMonth++;
                }
                else if(i < this.daysInNumbers[firstDay.getDay()])
                {
                    this.allDaysInMonth.push({day: 'prevMonth', isCurrentMonth: false, isSunday: false, isToday: false });
                    
                } else{
                    this.allDaysInMonth.push({ day: dayInNextMonth++, isCurrentMonth: false, isSunday: false, isToday: false });                    
                }
            }

            //fill previous months visible days
            var noOfDaysPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0).getDate();
            for(var i = 41; i >= 0; i--)
            {
                if(this.allDaysInMonth[i].day  == 'prevMonth')
                {
                    this.allDaysInMonth[i].day = noOfDaysPrevMonth--;
                }
            }      
        },
        getDateString(date)
        {
            return date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2);
        },
        selectDate(date)
        { 
            if(!date.isCurrentMonth)
            {
                return;
            }

            message.removeMessage();
            if(this.newBookingDays.start_date == null)
            {
                if(date.occupied)
                {
                    message.setInfoMessage("Du kan inte välja ett datum som redan är bokat.");
                    return;
                }
                date.selected = true;
                this.newBookingDays.start_date = date.dateString + " " + this.bookingItem.day_available_start_time;
                this.indexOfStart = this.allDaysInMonth.indexOf(date);
            }
            else{               
                var indexOfEndDate = this.allDaysInMonth.indexOf(date);

                if(indexOfEndDate < this.indexOfStart)
                {
                    this.allDaysInMonth.forEach(function(d) {
                        d.selected = false;
                    });
                    date.selected = true;
                    this.newBookingDays.start_date = date.dateString + " " + this.bookingItem.day_available_start_time;
                    this.indexOfStart = this.allDaysInMonth.indexOf(date);
                    this.indexOfEnd = null;
                    this.newBookingDays.end_date = null;
                }
                else if(indexOfEndDate-this.indexOfStart >= this.bookingItem.duration_time+1)
                {
                    message.setInfoMessage("Du har valt en längre bokningstid än vad som är möjligt. Maximal bokningstid är " + this.bookingItem.duration_time + " " + this.bookingItem.duration_unit);
                }
                else if(indexOfEndDate == this.indexOfStart)
                {
                    message.setInfoMessage("Du kan inte välja samma slutdatum som startdatum.");
                }               
                else{

                    for(var i = this.indexOfStart; i < indexOfEndDate; i++)
                    {
                        if(this.allDaysInMonth[i].occupied)
                        {
                            message.setInfoMessage("Du kan inte boka över en annan bokning.");
                            return;
                        }
                    }

                    date.selected = true;
                    this.indexOfEnd = indexOfEndDate;
                    this.newBookingDays.end_date = date.dateString  + " " + this.bookingItem.day_available_end_time;
                    this.allDaysInMonth.forEach(function(date) {
                        date.selected = false;
                    });

                    for(var i = this.indexOfStart; i <= this.indexOfEnd; i++)
                    {
                        this.allDaysInMonth[i].selected = true;
                    }
                }
            }          
        },    
        emptyBookingValues()
        {
            this.newBookingDays.start_time = null;
            this.newBookingDays.end_time = null;
            this.newBookingDays.start_date = null;
            this.newBookingDays.end_date = null;
            this.indexOfStart = null,
            this.indexOfEnd = null;
            this.start_time = null;
            this.allDaysInMonth.forEach(function(d) {
                d.selected = false;
            });
        },
        save()
        {   
            message.removeMessage();
            this.newBookingDays.booking_item_id = this.bookingItem.id;
            this.newBookingDays.duration_unit = this.bookingItem.duration_unit;
            this.newBookingDays.duration_time = this.indexOfEnd - this.indexOfStart;
            this.newBookingDays.customer_name = this.customerName;
            this.newBookingDays.booking_item_name = this.bookingItem.name;

            var validationErrors = [];
                   
            if(!this.newBookingDays.full_name == null || this.newBookingDays.full_name == '')
            {
                validationErrors.push("Du behöver mata in ett namn.");
            }
            if(!this.newBookingDays.email  == null || this.newBookingDays.email == '')
            {
                validationErrors.push("Du behöver mata in en email.");
            }
            if(!this.newBookingDays.agree_to_terms)
            {
                validationErrors.push("Du behöver bekräfta att du tagit del av informationen som berör bokningen.");
            }
            
            if(validationErrors.length > 0)
            {
                message.setValidationErrorMessage(validationErrors);
                return;
            }

            var self = this;
            this.$communicator.post('add-public-booking/', this.newBookingDays)
                .then(function(data)
                {
                    self.getBooking();                               
                    self.showConfirmation = true;                                  
                })
                .fail(function(error) {   
                    if(error.responseJSON.detail == 'already booked')
                    {
                        message.setValidationErrorMessage(["Du har redan en aktiv bokning registerad. Du kan endast ha en bokning registrerad i taget."]);  
                    }                    
                }) 
        },
        getColor(location)
        {
            return niceFunctions.getColor(this.publicPageColors, location)
        }    
    },
    computed:
    {
        isDayBooking() { return this.bookingItem != null ?  this.bookingItem.duration_unit == 'Timmar' : false  },
        url() { return config.urlToServer },  
        publicPageColors() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPageColors : null },
        publicPage() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPage : null },
        smsNotificationEnabled() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.smsNotificationEnabled : null },
        height() { return window.innerHeight/1.3 + 'px' },
        customerName() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.customerName : {} }
    }, 
    watch:
    {
        windowScroll(newvalue)
        {
            if( $("#BookingDaysAdd-calendar")[0] == undefined)
            {
                return false;
            }
            
            if(newvalue > $("#BookingDaysAdd-calendar")[0].offsetTop - window.innerHeight)
            {
                this.showBookingBar = true;
            }
            else
            {
                this.showBookingBar = false;
            }
        }
    }
}
</script>
<template>
    <div  class="public-page" v-if="bookingItem != null && publicPage != null" v-fade-in>
        <div class="top-public position-relative" :style="{ 'background-image': bookingItem.image_name != null ? 'url(' + url + 'uploads/'+ publicPage.customer_id+'/images/'+ bookingItem.image_name + ')' : '',  'height': height}">
            <SvgTransition :publicPage="publicPage"></SvgTransition>   
        </div>
        <div class="text-section" v-fade-in>
            <section style="padding-bottom: 150px;">    
                <h1 :style="{'color': getColor('header_color_on_white_bg') }">{{ bookingItem.name }}</h1>
                
                <div class="margin-top-15" v-html="this.$adaptHtml.adaptHtml(bookingItem.information, getColor('header_color_on_white_bg'), getColor('background_color'))"></div>
                <div class="info BookingAdd-info-box" :style="{ 'background-color': getColor('background_color') + ' !important', 'filter': 'brightness(90%)' }">
                    <b style="text-transform: uppercase;">Läs igenom följande information innan du bokar:</b>
                    <div class="margin-top-15" v-html="this.$adaptHtml.adaptHtml(bookingItem.rules,  getColor('header_color'), getColor('background_color'))"></div>
                    <p>Max bokningtid:<b> {{ bookingItem.duration_time }} dygn</b></p>
                </div>        
                <div class="g-100" id="BookingDaysAdd-calendar">
                    <div class="row">
                        <div class="width33 month-link" @click="showPrevMonth">&lt; {{ currentDate.getMonth() == 0 ? 'december' : months[currentDate.getMonth()-1] }}  </div>
                        <div class="width33 month">{{ this.month }} {{ this.currentDate != null ? this.currentDate.getFullYear() : '' }}</div>
                        <div class="width33 text-right month-link" @click="showNextMonth">{{ months[currentDate.getMonth()+1] }} &gt;</div>
                    </div>
                    <div class="row days">
                        <div v-for="day in days" class="width14">
                        {{ day }}               
                        </div>
                    </div>   
                    <div v-for="(day,index) in allDaysInMonth" class="width14 float-left dates" :style="{ 'border-left': index % 7 == 0 ? '1px dashed #ddd' : '' }" :class="{'BookingAdd-today-date': day.isToday, 'wow-color': day.selected }" @click="selectDate(day)">
                        <div class="day-content">
                            <i :style="{'opacity': day.isCurrentMonth ? '1' : '0.4', 'color': day.isSunday ? 'red': 'black' }" class="day-number">{{ day.isToday ? day.day + ' (idag)' : day.day }}</i>  
                            <div v-if="day.bookingEnd != undefined" class="BookingDaysAdd-occupied-end">
                                
                            </div> 
                            <div v-if="day.bookingStart != undefined" class="BookingDaysAdd-occupied-start">
                                <p>{{day.bookingStart.full_name }}</p>
                            </div>
                            <div v-if="day.bookingStart == undefined && day.bookingEnd == undefined && day.occupied" class="BookingDaysAdd-occupied-whole-day"></div>                     
                                        
                        </div>             
                    </div>  
                </div>  
                <div class="BookingDaysAdd-booking-times" v-if="showBookingBar" :style="{ 'background-color':  getColor('background_color') }" v-fade-in>          
                        <div>Välj startdatum:
                            <h2>{{ newBookingDays.start_date }} <span v-if="newBookingDays.start_date != null"> &nbsp;&nbsp;<button class="btn btn-gray" @click="emptyBookingValues">Välj nytt startdatum</button></span></h2>        
                        </div>
                        <div  :style="{'text-decoration': newBookingDays.start_date == null ? 'line-through' : 'none', 'color': newBookingDays.start_date == null ? '#ccc' : 'black'}">
                            Välj slutdatum:
                            <h2> {{ newBookingDays.end_date }}</h2>
                        </div>
                        <div>
                            <button class="btn BookingDaysAdd-confirm-btn" v-if="newBookingDays.end_date != null" @click="showSummary = true" :style="{ 'background-color': getColor('background_color') + ' !important' }">Visa sammanfattning och slutför bokningen</button>
                        </div>
                    </div>        
            </section>
        </div>
        <div v-if="showSummary && !showConfirmation">
            <PopUp>
                <h2>Bokning av {{ bookingItem.name }}
                    <span>
                        från {{ newBookingDays.start_date }}
                        till {{ newBookingDays.end_date }}
                    </span>  
                </h2>
                <label>Ditt namn<span style="font-family: 'Courier New', Courier, monospace;">*</span></label>
                <input type="text" v-model="newBookingDays.full_name" :style="{ 'background-color': getColor('background_color') + ' !important', 'filter': 'brightness(120%)' }" />
                <div class="row-form">
                    <div class="g-50" :class="smsNotificationEnabled ? 'g-50' : 'g-100'"> 
                        <label>Din email (Hit skickas ett bekräftelsemail)<span style="font-family: 'Courier New', Courier, monospace;">*</span></label>
                        <input type="text" v-model="newBookingDays.email" :style="{ 'background-color': getColor('background_color') + ' !important', 'filter': 'brightness(120%)' }" />
                    </div>            
                    <div class="g-50" v-if="smsNotificationEnabled">
                        <label>Ditt mobilnummer</label>
                        <input type="text" v-model="newBookingDays.mobileNo" :style="{ 'background-color': getColor('background_color') + ' !important', 'filter': 'brightness(120%)' }"/>
                    </div>              
                </div>
                <div class="row-form margin-bottom-15" v-if="smsNotificationEnabled && newBookingDays.mobileNo != null && newBookingDays.mobileNo != ''" v-fade-in>
                    <div class="g-100" style="overflow:hidden">
                        <label style="margin-top: 15px;">Skicka även bokningsbekräftelse och påminnelse till valt mobilnummer</label>
                        <Switch :model="newBookingDays" :prop="'send_reminder_to_mobile_no'"></Switch>
                    </div>
                </div>
                <div class="row-form">
                    <div class="g-100">
                        <label>Lägg till en kommentar (frivillig, kommer bara synas för dig)</label>
                        <textarea v-model="newBookingDays.comments" class="margin-bottom-15" :style="{ 'background-color': getColor('background_color') + ' !important', 'filter': 'brightness(120%)' }"></textarea>
                    </div>
                </div>
                <div class="info BookingAdd-info-box" :style="{ 'background-color': getColor('background_color') + ' !important', 'filter': 'brightness(90%)' }">
                    
                    <Switch :model="newBookingDays" :prop="'agree_to_terms'"></Switch>
                    <b style="text-transform: uppercase;">&nbsp; Jag bekräftar att jag tagit del av informationen som berör bokningen</b>
                </div>
                <div class="margin-top-15 float-right">
                    <button class="btn btn-gray" style="padding: 10px"  @click="showSummary = false">Gå tillbaka och ändra i bokningen</button>                   
                    <button class="btn" :style="{ 'background-color': getColor('background_color') + ' !important', 'filter': 'brightness(90%)','padding': '10px' }"  @click="save">Slutför bokningen</button>
                </div>         
            </PopUp>
        </div>
        <div v-if="showConfirmation">
            <PopUp :width="this.$isMobile ? '90%' : '600px'" :top="'60px'">
                <div style="text-align: center;">
                    <h2>Härligt! Bokningen av {{ bookingItem.name }}<br> mellan {{ newBookingDays.start_date }} - {{ newBookingDays.end_date }} lyckades!</h2> 
                    Inom kort får du ett bekräftelsmail skickat till {{ newBookingDays.email }} (Håll utkik i skräpkorgen ifall den olyckligtvis hamnat där). <br><span style="font-size: 51pt; display: block">&#127870;</span> <br><strong>Vi ses snart! </strong> <br>             
                    <button class="btn" :style="{ 'background-color': publicPage.background_color + ' !important', 'filter': 'brightness(90%)','padding': '10px', 'margin': '30px 0px' }" @click="closeConfirm"> Tillbaka till startsidan</button>
                </div>
            </PopUp>
        </div>
    </div>
</template>
<style scoped>
    section{
        width: 100%;
        overflow: auto;
    }

    .row-form{
        width:calc(100% - 10px);
        overflow: auto;
    }

    .day-content
    {
        
        height: 120px;
        
    }
    
     .day-content:hover
    {
      
       cursor: pointer;
    } 

    .day-content p
    {
        font-size: 13px;
        background-color: rgb(245, 175, 175);
        padding:5px;
        margin:0px;
        line-height: 15px;
    }
    
    .row div{
        padding:10px 0px;

    }
    
    .dates {
        padding:4px 0px;
        border-right: 1px dashed #ddd;
   
        border-bottom: 1px dashed #ddd;

    }
    
    .month{
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
    }

    .month-link
    {
        cursor: pointer;
    }
    
    .days{
        text-align: center;
        font-weight: bold;
    } 
    
    .days div{
        border-right: 1px dashed #ddd;
        border-top: 1px dashed #ddd;
        border-bottom: 1px dashed #ddd;
        
    }

    .days div:first-child{
        border-left: 1px dashed #ddd;
    }

    .day-number
    {
        display: block;
    }
    .row div{
        float: left;
    }

    .width14
    {
        width: calc(14.2% - 2px);
    }

    .BookingAdd-hour
    {
        border-top: 1px dashed #ddd;
        height: 30px;
        position: relative;
    }
    
    .BookingAdd-hour-content
    {
        margin-top:56px; 
    }

    .BookingAdd-today-date
    {
        background-color: #ddd;
    }
   

    .BookingAdd-booking-selected
    {
        background-color: rgb(243, 235, 161);
        width: 80%;
        height: 30px;
        float: left;
        margin-top: 2px;
        margin-left: 100px;
        position: absolute;
        top: 0px;
        z-index: 1;
        border-bottom: 1px solid  rgb(243, 235, 161);
    }

    .BookingAdd-hour-text
    {
        margin-top: -11px;
        width:45px;
        padding-bottom: 12px;
        background-color: white;
    }

    .BookingAdd-info-box
    {
        padding:25px;
        margin-bottom: 15px;
    }

   label{
    margin-bottom: 4px;  
    }
   .BookingDaysAdd-occupied-start
   {
    background-color: rgb(245, 175, 175);
    width:70%;
    height:100px;
    margin-left: 30%;
   }
  
   .BookingDaysAdd-occupied-end
   {
    background-color: rgb(245, 175, 175);
    width:30%;
    height:100px;
    margin-left: -1px;
    float:left;
   }
   
   .BookingDaysAdd-occupied-whole-day
   {
    background-color: rgb(245, 175, 175);
    width:calc(100% + 2px);
    height:100px;
    margin-left:-1px;
   }

   textarea
   {
    height: 100px;
    width: calc(100% - 30px);
    border: 1px solid lightgray;
    padding: 10px;
   }

   input {
    border: 1px solid lightgray;
   }

   
   .BookingDaysAdd-confirm-btn {
        border: 2px solid black;
        border-radius: 5px;
        filter: brightness(90%);
        padding: 10px;
        font-weight: bold !important;
    }
    
    .BookingDaysAdd-confirm-btn:hover {      
        filter: brightness(70%);     
    }

    .BookingDaysAdd-booking-times
    {
        padding:14px 10px 25px 10px;
        margin-top:30px; 
        border-radius:5px; 
        text-align: center;
        overflow: auto;
        position: fixed;
        bottom: 0px;
        width: 100%;
        left: 0px;
        z-index: 100;
    }

    .BookingDaysAdd-booking-times div{
        float: left;
        width: 33%;
        height: 100px;
    }

@media only screen and (max-width: 900px) {
    .BookingDaysAdd-booking-times
    {      
        height: 90px;
        padding: 20px 10px;
    }
}

</style>