<script>
import Logo from '@/components/Logo.vue'
import links from '@/helpers/saleLinks'
export default {
    components: { Logo },
  data() {
    return {  
      showMobileMenu: false,
      links: links.links()
    }
  }, 
  methods:
  {
    goTopage(page)
    {   
      this.showMobileMenu = false;  
        this.$router.push({ name: page, params: { id: this.$route.params.id } })       
    }   
  },
  watch:
  {
    customerName(newvalue)
    {
      document.title = newvalue;
    }
  },
  computed: {  
    height() { return $(window).height() }
  },
}
</script>
<template>
  <section class="MobileMenu" v-fade-in>
    <div class="MobileMenu-icon">     
      <p @click="showMobileMenu = !showMobileMenu">             
        <hr :class="showMobileMenu ? 'MobileMenu-line-fade-out' : 'MobileMenu-line-fader'">
        <hr :class="showMobileMenu ? 'MobileMenu-line-x' : 'MobileMenu-line-x-straight'">
        <hr :class="showMobileMenu ? 'MobileMenu-line-y' : 'MobileMenu-line-y-straight'">
        <hr :class="showMobileMenu ? 'MobileMenu-line-fade-out' : 'MobileMenu-line-fader'">        
        <b v-if="!showMobileMenu">MENY</b>
      </p>     
    </div>
    <div v-if="showMobileMenu">       
        <div class="MobileMenu-links" style="" :style="{ 'height': height + 'px' }">
          
          <div v-for="link in links" @click="goTopage(link.componentName)" class="MobileMenu-item">
            {{  link.title }}
          </div>      
        
          <div class="MobileMenu-bg">
            <Logo :width="130" :color="'rgb(225, 182, 117)'" :marginAuto="true" :opacity="0.1" :useBlackLogo="true"></Logo>
          </div>
        </div>  
        <div class="MobileMenu-triangle">
        </div>
      </div>  
  </section>
</template>


<style scoped>
.MobileMenu-icon
{
  overflow: auto; 
  position: absolute; 
  z-index: 100; 
  right: 0px;
}
.MobileMenu-links
{
  position: absolute; 
  width:calc(100% - 140px); 
  z-index: 10; 
  top: 0px;
  padding-bottom:95px;
  padding-top: 15px;  
  background-color: rgb(225, 182, 117);
}
.MobileMenu
{ 
  height: 120px; 
  color: rgba(31, 57, 87, 1);
  position: fixed;
  z-index: 999;
  top: 0px;
  left: 0px;
  right: 0px;
}
  .MobileMenu b {
    color: white;
    text-shadow: -1px -1px 0 rgb(0, 0, 0), 1px -1px 0 rgb(0, 0, 0), -1px 1px 0 rgb(0, 0, 0), 1px 1px 0 rgb(0, 0, 0);
  } 
  .MobileMenu p 
  {
    font-size: 11pt;
    font-weight: bold;
    text-align: center;
    margin: 17px 30px;
    width: 45px;
    float: right;
    cursor: pointer;
  }  
  .MobileMenu hr {
    height: 5px;
    width: 40px;
    margin: 2px;
    float: right;
    background-color: white;
    border: 1px solid black;
    border-radius: 3px;
  }  
.MobileMenu-line-fade-out
{
  opacity: 0;
  transition: 0.3s;
}
.MobileMenu-line-x
{
  transform: rotate(135deg);
  background-color: rgba(31, 57, 87, 1) !important;
  border: 1px solid rgba(31, 57, 87, 1) !important;
  transition: 0.3s;
}
.MobileMenu-line-y
{
  transform: rotate(45deg);
  transition: 0.3s;
  background-color: rgba(31, 57, 87, 1) !important;
  border: 1px solid rgba(31, 57, 87, 1) !important;
  position: absolute;
  top: 28px;
  right: 30px;
}
.MobileMenu-line-y-straight
{
  transform: rotate(0deg);
  transition: 0.3s;
  position: absolute;
  top: 28px;
  right: 30px;
}
.MobileMenu-line-x-straight
{
  transform: rotate(180deg);
  transition: 0.3s;
}
.MobileMenu-line-fader
{
  opacity: 1;
  transition: 0.6s;
}
.MobileMenu-item
{
  font-size: 17pt;
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  padding: 16px 0px 0px 30px;
  cursor: pointer;
  filter: brightness(90%);
  width: 120%;
}
.MobileMenu-triangle {
  width: 0;
  height: 0;
  border-bottom: 1500px solid transparent;
  border-left: 274px solid transparent;
  position: absolute;
  top: 0px;
  right: 0px;
  border-left-color: rgb(225, 182, 117);
}
.MobileMenu-bg
{
  width:150px;
  height: 150px;
  position:absolute;
  bottom: 140px;
  left: 0px;
  right: 0px;
  margin: auto;

}
</style>
