export default {
  scrollToElement(elementId) {
    var scrollTop = document.getElementById(elementId).getBoundingClientRect().top + document.documentElement.scrollTop - 100;
    $("html, body").animate({ scrollTop: scrollTop });
  },
  getColor(colors, location)
  {
      if(colors == null) { return ''}
      var color = colors.find(x => x.location == location);

      return color != undefined ? color.color : '';
  },
  getPageName(page)
  {
      if(window.location.hostname != 'www.jami.se')
      {
        return 'domain_' + page;
      }
      return page;
  }

}