<script>
import Logo from '@/components/Logo.vue'
import links from '@/helpers/saleLinks'
export default {
  components: {Logo},
  data() {
    return {  
        links: links.links()
      
    }
  }, 
  props:['windowScroll'],
  created()
  {
   
  },
  computed: {
    selectedPage() { return this.$route.name }
  },
  watch:
  {
    customerName(newvalue)
    {
      document.title = newvalue
    }
  },
  methods:
  {
    goTopage(page)
    {
      $(window).scrollTop(0);   
        this.$router.push({ name: page })
          
    }
  }
}
</script>
<template>
  <section class="Menu" v-fade-in>
    <Logo class="Menu-logo-placement" :width="80" :color="'rgb(225, 182, 117)'" :marginAuto="true" :opacity="1"></Logo>
    <div :class="[windowScroll > 200 || selectedPage != 'saleHome' ? 'Menu-addedWhite' : 'Menu-noBg']">
      <ul>
        <li v-for="link in links" @click="goTopage(link.componentName)" :style="{'background-color': link.componentName == selectedPage ? '#d8b597' : ''}">
          {{  link.title }}
        </li>      
      </ul>
    </div>
  </section>
</template>
<style scoped>

.Menu-logo-placement
{
  position: absolute; 
  top: 34px; 
  left: 34px
}
.Menu-order-btn
{
  position: absolute;
    right: 10px;
    top: 13px;
    font-size: 14pt;
    border-radius: 31px;
    padding: 14px 26px;
    color: white;
    font-weight: 500;
    border: 2px solid white;
}
.Menu
{
  width:100%; 
  height: 120px; 
  color: rgb(238, 236, 236);
  position: fixed;
  z-index: 20;
  text-shadow: 1px 1px 2px rgb(0, 0, 0);
}
.Menu-addedWhite
{
  background: rgba(255,255,255,0.97);
  /* text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; */
  border-bottom: 6px solid #00131c;
  text-shadow: none;
  color:#00131c;
  transition: 0.3s;
}

.Menu-addedWhite li:hover
{
  background-color: #d8b597;
  /* background-color: rgb(225, 182, 117); */
}
.Menu-noBg
{
  background: none;
  transition: 0.3s;
}
ul{
  text-align: center;
  margin: auto;
  padding: 0px;
  display: flex;
  justify-content: center;
  font-family: 'Raleway', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
li{ 
  display: inline-block;
    vertical-align: top;
    padding: 22px 30px;
    text-align: center;
    font-size: 13pt;
    font-weight: bold;
    cursor: pointer;
}

@media only screen and (max-width: 1400px) {
  li{ 
 
    padding: 22px 20px;
  
    font-size: 11pt;
   
}
}
</style>
