export default {
    links() {   
      return [
          {componentName: 'saleHome', title: 'Hem' },         
          {componentName: 'saleTrial', title: 'Prova på' },
          {componentName: 'saleOrder', title: 'Beställ' },
          {componentName: 'saleCost', title: 'Pris' },
          {componentName: 'saleExample', title: 'Exempel' },
          {componentName: 'saleDemo', title: 'Demo' }
    ];
    }
}