<script>
import config from "@/helpers/config"
import ImageGalleryPopUp from '@/components/ImageGalleryPopUp.vue'
import niceFunctions from "@/helpers/niceFunctions"
import SvgTransition from '@/components/SvgTransition.vue'
export default {
    components: { ImageGalleryPopUp, SvgTransition },
    data() {
        return {  
            selectedImage: { image: null },
            imgHeight: $(window).height() - 100,
        }
    }, 
    created() {  
        this.$cache.createCache('publicPage', 'public-page/',  {id: this.$route.params.id, domain: window.location.hostname });      
       
    },
    methods: {
        blurOut(image)
        {
            if(this.images.filter(x => x.hovered) == 0) {
                return false;
            }
            else if(image.hovered) {
                return false;
            }
            return true;
        },
        getColor(location)
        {
            return niceFunctions.getColor(this.publicPageColors, location)
        } 
    },
    computed: {
        url() { return config.urlToServer },  
        publicPageColors() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPageColors : null },
        publicPage() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPage : {} },
        images() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.images : {} },
        height() { return window.innerHeight/1.3 + 'px' },
        settings() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.settings : null },
        publicPageImages() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPageImages : null },
        hasSettings() { return this.settings }
  }
}
</script>
<template>
   <section class="public-page" v-if="publicPageImages != null" v-fade-in>
        <div class="top-public position-relative" :style="{ 'height': height}" v-background="publicPageImages.find(x => x.location == 'image_gallery')">
            <SvgTransition :publicPage="publicPage" :color="getColor('background_color')"></SvgTransition>   
        </div>
        <div :style="{ 'background-color': getColor('background_color') }">
            <div class="text-section">
                <h1 :style="{ 'color': getColor('header_color') }"> {{ hasSettings ? settings.ImageGallery.singular : '' }}</h1>
                <div class="ImageGallery-image-section">
                    <div v-for="image in images" class="ImageGallery-image">
                        <img :src="url + 'uploads/'+ publicPage.customer_id+'/images/thumbnails/'+ image.name" @click="selectedImage.image = image" @mouseover="image.hovered = true" @mouseout="image.hovered = false" :class="blurOut(image) ? 'ImageGallery-grayscale' : 'ImageGallery-no-grayscale'" />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="selectedImage.image != null">
            <ImageGalleryPopUp :selectedImage="selectedImage" :images="images">
            </ImageGalleryPopUp>
        </div>
   </section>
</template>
<style scoped>

.ImageGallery-image-section {
    padding: 10px; 
    display:flex;
    flex-wrap: wrap; 
    justify-content: space-evenly;
}

.ImageGallery-image img
{
    height: 200px;
    margin: 7px;
    box-shadow: 1px 1px #9b9b9b;
    cursor: pointer;
    
}

.ImageGallery-grayscale {
    filter:grayscale(70%);
    transition: ease-out 0.2s;
}

.ImageGallery-no-grayscale {
    filter:grayscale(0%);
    transition: ease-out 0.2s;
}


@media only screen and (max-width: 900px) {
    .ImageGallery-img-area
    {
    width: calc(50% - 10px);
    max-height: 150px;
    overflow: hidden;
    min-height: 150px;
    }

    .ImageGallery-image img
    {
        width: 100%; 
        height: auto;
    }
}
</style>